import React, { Component } from 'react';
import { addFutureClassName, getTotalsPerDay } from '../../../../helpers';
import { Text } from 'elmo-elements';
import { getCurrencyFormatted, getPreferenceLabel } from 'lib/helpers';
import { compact } from 'lodash';
import { RosterCell } from '../../../../components/RosterCell';
import {
  Event,
  LanguagePreferences,
  PreferencesCurrencyCode,
  PreferencesCurrencyPlacement,
  PreferencesNumberFormat,
} from 'type/models';
import { StringMap } from '../../../../../../type';
import { EventData } from './types';
import moment, { Moment } from 'moment';

type Props = {
  data: {
    rosters: EventData[][];
    rostersRowNumber: number;
  };
  weekDays: any;
  events: StringMap<Event>;
  langPreferences: LanguagePreferences;
  canShowModal: () => void;
  numberFormat: PreferencesNumberFormat;
  currencyCode: PreferencesCurrencyCode;
  currencyPlacement: PreferencesCurrencyPlacement;
  emptyCellClick: (day: string) => boolean;
  getData: (event: StringMap<Event>, id: string) => EventData | null;
  showEventCard: (rowNum: number, dayNum: number) => React.ReactNode;
  currentTimeBySiteTimezone: Moment;
};

export class CollapsableEventsRowView extends Component<Props> {
  render() {
    const { rosters } = this.props.data;
    return (
      <React.Fragment>
        {this.showRowsPlaceholder()}
        {rosters && this.showTotalsPerDay()}
      </React.Fragment>
    );
  }

  cellClassName = (dayIndex: number) => {
    const { weekDays, currentTimeBySiteTimezone } = this.props;
    return addFutureClassName(
      currentTimeBySiteTimezone,
      moment(weekDays[dayIndex], 'x')
    );
  };

  showTotalsPerDay = () => {
    const { data, events, numberFormat, currencyCode, currencyPlacement } =
      this.props;
    const totals = getTotalsPerDay(data.rosters, events);
    const getTotal = (value: number) => {
      return getCurrencyFormatted(
        numberFormat,
        currencyCode,
        currencyPlacement,
        value,
        true
      );
    };
    return (
      <div className="week-rosters-grid__group total-info-wrapper">
        {totals.map(
          (day: { value: number; hasEvent: boolean }, dayIndex: number) => (
            <div
              className={
                'week-rosters-grid__group-day-block total-info' +
                this.cellClassName(dayIndex)
              }
              key={dayIndex}
            >
              {day.hasEvent ? (
                <Text size={'xs'} isBold={true} className={'total-info__label'}>
                  <strong>Total</strong> <span>{getTotal(day.value)}</span>
                </Text>
              ) : (
                <Text size={'xs'} isBold={true}>
                  -
                </Text>
              )}
            </div>
          )
        )}
      </div>
    );
  };

  showRowsPlaceholder = () => {
    const {
      data: { rosters },
    } = this.props;
    return (
      rosters &&
      rosters.map((row: any, rowIndex: number) => {
        return (
          !!compact(row).length && (
            <div className="week-rosters-grid__group-wrapper" key={rowIndex}>
              <div className="week-rosters-grid__group week-rosters-grid__group--hidden">
                <div className="week-rosters-grid__group-day-block">
                  <RosterCell type={'top'}>
                    <div
                      style={{ height: '100%', minHeight: this.getMinHeight() }}
                    />
                  </RosterCell>
                </div>
              </div>
              <div className="week-rosters-grid__group week-rosters-grid__group--absolute">
                {row.map((cell: any, dayIndex: number) => (
                  <React.Fragment key={dayIndex}>
                    {cell.hasOwnProperty('id')
                      ? this.getCardCell(cell.duration, rowIndex, dayIndex)
                      : this.getEmptyCell(rowIndex, dayIndex)}
                  </React.Fragment>
                ))}
              </div>
            </div>
          )
        );
      })
    );
  };

  getDayCell = (rowIndex: number, dayIndex: number) => {
    return (
      <div className="week-rosters-grid__group-day-block">
        {this.getCellContent(rowIndex, dayIndex)}
      </div>
    );
  };

  getEmptyCell = (rowIndex: number, dayIndex: number) => {
    const {
      data: { rosters },
    } = this.props;
    const dayCell = rosters[rowIndex][dayIndex];
    return !dayCell.hasOwnProperty('isFilledBy')
      ? this.getDayCell(rowIndex, dayIndex)
      : null;
  };

  getCardCell = (duration: number, rowIndex: number, dayIndex: number) => {
    const getWidth = (): string => {
      return `calc((100% / 7) * ${duration} - 4px)`;
    };
    return (
      <div
        className="week-rosters-grid__group-day-block"
        style={{ width: getWidth() }}
        key={dayIndex}
      >
        {this.getCellContent(rowIndex, dayIndex)}
      </div>
    );
  };

  getCellContent = (rNum: number, dNum: number) => {
    const {
      data: { rosters },
      events,
      weekDays,
    } = this.props;
    const current = rosters[rNum][dNum];
    const roster =
      current && current.id
        ? this.props.getData(events, rosters[rNum][dNum].id)
        : null;
    const day = weekDays[dNum];
    const label = getPreferenceLabel(
      this.props.langPreferences,
      'event',
      'singular',
      'Event',
      true
    );
    const cellParams = !roster
      ? {
          label: `+ Add ${label}`,
          fontWeight: 'normal',
          labelOnHover: true,
        }
      : undefined;
    return (
      <RosterCell type={'top'} {...cellParams}>
        <div
          key={dNum}
          onClick={() => this.props.emptyCellClick(day)}
          style={{ height: '100%', minHeight: this.getMinHeight() }}
        >
          {this.props.showEventCard(rNum, dNum)}
        </div>
      </RosterCell>
    );
  };

  getMinHeight = () => {
    return '56px';
  };
}
